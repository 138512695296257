const theme = {
  color: {
    white: 'antiquewhite',
    gray: '#777',
    black: '#172223',
    primary: '#F25C05',
    secondary: '#BF0436',
    patches: ['#006543', '#D5BAD9', '#0477BF', '#F2A007', '#B66929', '#F88FBF'],
  },
  font: {
    paragraph: '"Cardo", serif',
    heading: '"Lora", serif',
  },
};

export default theme;
