import { createGlobalStyle } from 'styled-components';
// import reset from 'styled-reset-advanced';
import theme from './theme';


const GlobalStyle = createGlobalStyle`
body {
  background-color: ${theme.color.white};
  margin: 0;
  font-size: 18px;
  a {
    color: inherit;
    text-decoration: inherit;
  }
  font-family: ${theme.font.paragraph};
  h1,h2,h3,h4,h5,h6 {
    font-family: ${theme.font.heading};
  }
}
`;

export default GlobalStyle;
