import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import 'typeface-cardo';
import 'typeface-lora';
import theme from '../assets/theme';
import GlobalStyle from '../assets/global-style';

const Header = styled.header`
width: 100%;
height: 70px;
background-image: url(/banner.jpg);
background-size: cover;
background-position: center bottom;
color: ${(props) => props.theme.color.white};
font-family: ${(props) => props.theme.font.heading};
padding-top: 60px;
position: relative;
`;


const Footer = styled.footer`
width: 100%;
height: 50px;
background-image: url(/banner.jpg);
background-size: cover;
background-position: center bottom;
color: ${(props) => props.theme.color.white};
font-family: ${(props) => props.theme.font.heading};
text-align: center;
line-height: 50px;
font-weight: 600;
font-size: 36px;
`;


const HeaderMain = styled.div`
font-size: 92px;
line-height: 86px;
font-weight: 600;
padding-left: 20px;
@media (max-width: 1250px) {
    font-size: 70px;
    line-height: 102px;
}
@media (max-width: 1000px) {
    display: none;
}
`;

const HeaderSub = styled.div`
font-size: 32px;
font-weight: 600;
padding-left: 20px;
position: absolute;
bottom: -11px;
right: 5px;
@media (max-width: 1000px) {
    font-size: 22px;
    bottom: -7px;
    right: 0;
}
`;

const PlainHeader = styled.div`
font-family: ${(props) => props.theme.font.heading};
text-align: center;
font-size: 34px;
@media (min-width: 350px) {
font-size: 40px;
}
@media (min-width: 450px) {
font-size: 50px;
}
@media (min-width: 1000px) {
    display: none;
}
`;


const Layout = ({ children }) => (
    <ThemeProvider theme={theme}>
        <Fragment>
            <GlobalStyle />
                <Link to="/">
            <Header>
            <HeaderSub>Views from everywhere</HeaderSub>
                <HeaderMain>Aerial Patchwork</HeaderMain>
            </Header>
                    <PlainHeader>Aerial Patchwork</PlainHeader>
            </Link>
            <div>
                {children}
            </div>
            <Link to="/">
            <Footer> ⤛⤛⤜⤜  </Footer>
            </Link>
        </Fragment>
    </ThemeProvider>
);

export default Layout;
