const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'Sepetember',
  'October',
  'November',
  'December',
];

const prettyDate = (datestr) => {
  const d = new Date(datestr);
  return `${d.getDate()} ${months[d.getMonth()]}`;
};

const shortDate = (datestr) => {
  const d = new Date(datestr);
  return `${d.getDate()}/${d.getMonth() + 1}`;

  const prettyDate = (datestr) => {
    const d = new Date(datestr);
    return `${d.getDate()} ${months[d.getMonth()]}`;
  };
};

export { prettyDate, shortDate };
